﻿"use strict";
class BTWidgetContent {
  /**
   * To be used in the iframe content - in every page.
   */
  private lastMeasuredHeight: number;
  private allowedHost: string;
  private hostContainerId: string;
  private legacyIE: boolean;

  constructor() {
    if (!parent || !parent.window) {
      console.log("BT Widget Content: no host iframe detected.");
      return;
    }
    if (window.location.href.indexOf("host=") === -1) {
      console.log(
        "BT Widget Content: no host parameter detected. Can not initialize the communication with parent iframe."
      );
      return;
    }
    var owner: BTWidgetContent = this;
    this.legacyIE =
      navigator.userAgent.indexOf("MSIE 9.0") !== -1 ||
      navigator.userAgent.indexOf("MSIE 10.0") !== -1;
    this.lastMeasuredHeight = 0;
    this.StartMeasuringDocHeight();
    this.allowedHost = decodeURIComponent(
      window.location.href.match(/host=(.+?)(?:&|#|$)/i)[1]
    );
    try {
      this.hostContainerId = window.location.href.match(
        /containerid=(.+?)(?:&|#|$)/i
      )[1];
    } catch (e) {}

    // Hide elements like close buttons if the widget is loaded from an embedded enviroment.
    if (this.hostContainerId) {
      try {
        var styleElement: HTMLStyleElement = document.createElement("style");
        styleElement.setAttribute("type", "text/css");
        styleElement.innerText = ".btHideIfEmbedded {display:none;}";
        var body: HTMLBodyElement = document.getElementsByTagName("body")[0];
        body.appendChild(styleElement);
      } catch (e) {}
    }

    // measure the document when this script loads
    this.MeasureDocHeight();

    // measure the document once it fully loads
    window.addEventListener("load", function (this, ev) {
      owner.SendEventPageLoaded();
      owner.MeasureDocHeight();
    });

    window.addEventListener("unload", function (this, ev) {
      owner.SendEventPageUnloaded();
    });

      window.addEventListener("redirectparent.bt", function (this, ev:any) {
      // if (typeof(ev) !== typeof(CustomEvent)) return;
      if (ev.detail) {
        owner.RedirectParent(ev.detail);
      } else {
        console.error("The URL has not been set");
      }
    });

    // any element with btCloseWidget will close the parent modal window
    window.addEventListener("click", function (this, ev) {
      var targetElement: HTMLElement = ev.target as HTMLElement;

      // We need to search recursively for a parent with the triggering class, because the target element could be a nested element in an <a> tag.
      var ClassSearcher = function (targetElement: HTMLElement) {
        if (targetElement.className.indexOf("btConfirmAndCloseWidget") !== -1) {
          var message = targetElement.getAttribute("data-message");
          if (!message) {
            return;
          }
          owner.ConfirmAndCloseModal(message);
          return;
        }
        if (targetElement.className.indexOf("btCloseWidget") !== -1) {
          owner.CloseModal();
          return;
        }

        if (targetElement.parentElement) {
          ClassSearcher(targetElement.parentElement);
        }
      };
      ClassSearcher(targetElement);
    });

    // Grant consent, if a special element exists on the page
    if (document.getElementById("btgrantconsent")) {
      owner.GrantConsent();
    }
  }

  /**
   * This method measures the document height in a given (short) interval, to measure dom changes that don't usually trigger the resize event.
   */
  private StartMeasuringDocHeight(): void {
    var owner: BTWidgetContent = this;
    setInterval(function () {
      owner.MeasureDocHeight();
    }, 200);
  }

  /**
   * Measure the document height, and inform the parent
   */
  private MeasureDocHeight(): void {
    var docHeight: number = this.legacyIE
      ? document.documentElement.scrollHeight +
        (document.documentElement.style.paddingTop
          ? parseInt(document.documentElement.style.paddingTop)
          : 0) +
        (document.documentElement.style.paddingBottom
          ? parseInt(document.documentElement.style.paddingBottom)
          : 0)
      : document.documentElement.offsetHeight;
    // Only trigger the event if the measured height actually differs, to prevent sending too many events.
    if (docHeight !== this.lastMeasuredHeight) {
      var data: IBTMessages = {
        namespace: "bt",
        action: BTWidgetActionsEnums.resize,
        data: {
          containerId: this.hostContainerId,
          height: docHeight,
        },
      };
      parent.window.postMessage(data, this.allowedHost);
    }
    this.lastMeasuredHeight = docHeight;
  }

  /**
   * Ask before closing the parent window
   * @param message The question you want to ask before closing.
   */
  private ConfirmAndCloseModal(message: string): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.confirmAndClose,
      text: message,
    };
    parent.window.postMessage(data, this.allowedHost);
  }

  /**
   * Close the parent window
   */
  private CloseModal(): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.close,
    };
    parent.window.postMessage(data, this.allowedHost);
  }

  /**
   * Redirect the parent window
   */
  private RedirectParent(url: string): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.redirectParent,
      data: {
        url: url,
      },
    };
    parent.window.postMessage(data, this.allowedHost);
  }

  /**
   * Confirm conset granted and close the parent window
   */
  private GrantConsent(): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.grantConsent,
    };
    parent.window.postMessage(data, this.allowedHost);
  }

  /**
   * Page has loaded - inform the parent window, to hide the loading animation
   */
  private SendEventPageLoaded(): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.loaded,
    };
    parent.window.postMessage(data, this.allowedHost);
  }

  /**
   * Leaving the current page - trigger the loading animation in the parent window
   */
  private SendEventPageUnloaded(): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.unloaded,
    };
    parent.window.postMessage(data, this.allowedHost);
  }

  public consolelog(offsetTop: number): void {
    var data: IBTMessages = {
      namespace: "bt",
      action: BTWidgetActionsEnums.scrollTo,
      data: {
        height: offsetTop,
      },
    };
    parent.window.postMessage(data, this.allowedHost);
  }
}

enum BTWidgetActionsEnums {
  open,
  resize,
  confirmAndClose,
  close,
  loaded,
  unloaded,
  grantConsent,
  redirectParent,
  scrollTo,
  gtmPost,
  refresh,
  urlParam,
  restrainResize
}

interface IBTMessages {
  namespace: string;
  action: BTWidgetActionsEnums;
  data?: IBTMessagesDatas;
  text?: string;
}

interface IBTMessagesDatas {
  containerId?: string;
  height?: number;
  url?: string;
}

var bTWidgetContent = new BTWidgetContent();
